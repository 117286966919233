// @ts-strict-ignore
// Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.
import { DecimalPipe } from '@angular/common';
import { AfterViewChecked, Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Observable, fromEvent } from 'rxjs';
import { filter, first, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AuthService } from './core/auth/auth.service';
import { CurrencyService } from './currency/currency.service';
import { AnalyticsService } from './shared/analytics.service';
import { escapeTextByDefault } from './shared/datatables.util';
import { CurrencyConverterPipe } from './shared/pipes/currency-converter.pipe';
import { PrecisionPipe } from './shared/pipes/precision-pipe';
import { isDemoSite } from './shared/util';
import { UserService } from './user/user.service';

const defaultPageTitle = environment.defaultPageTitle;

/**
 * Get numbers from a string. Numbers must be at the begining of the string.
 * Accepts very small numbers (e.g. 1e-10) and scientific notation.
 *
 * A specific use case by DataTables API footer callback configuration, during
 * {@link AppComponent} initialisation.
 *
 * @param s string where numbers must be at the beginining.
 * @returns string containing numbers only
 */
// export for unit testing only. The use case of this is very specific, IMHO don't see this needed elsewhere.
export const numbersFromString = (s: string) => {
  // Remove any spaces in the string. Remove any commas commonly used as number separators in string.
  // If not; "3,000" or "3, 000" will become "3" by regex later. We don't try to include commas in the regex, coz
  // our return value is passed straight to a Number constructor. Commas will cause the Number constructor to
  // return NaN unexpectedly.
  const cleaned = s.replace(/[\s,]+/g, '');
  const res = /([-.\deE]+)/.exec(cleaned);
  return res?.[1];
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewChecked {
  title = 'fair-supply-analytics';

  showFooter = false;

  resize$: Observable<Event>;

  private readonly pageTitle$: Observable<string>;

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private precisionPipe: PrecisionPipe,
    private decimalPipe: DecimalPipe,
    private analyticsService: AnalyticsService,
    private currencyService: CurrencyService,
    private readonly currencyConverterPipe: CurrencyConverterPipe,
  ) {
    this.pageTitle$ = this.router.events.pipe(
      takeUntilDestroyed(),
      filter(event => event instanceof NavigationEnd),
      map(() => {
        let child = this.activatedRoute.firstChild;

        if (!child) {
          return this.activatedRoute.snapshot.data.title || defaultPageTitle;
        }

        while (child.firstChild) {
          child = child.firstChild;
        }

        if (child.snapshot.data.title) {
          return child.snapshot.data.title || defaultPageTitle;
        }
      }),
    );

    this.resize$ = fromEvent(window, 'resize').pipe(takeUntilDestroyed());
  }

  ngOnInit() {
    // Safari Bug - https://developer.apple.com/forums/thread/651215
    // DOMContentLoaded doesn't fire so use the document.readyState === 'interactive' or 'complete'.
    // Could potentially refactor to no longer need to use the DOMContentLoaded condition.
    if (document.readyState === 'loading') {
      document.addEventListener('DOMContentLoaded', () => {
        // Ensure the selected currency is defined early
        this.currencyService.initialise();
      });
    } else {
      // Ensure the selected currency is defined early
      this.currencyService.initialise();
    }

    this.pageTitle$.subscribe((title: string) => {
      if (title) {
        this.titleService.setTitle(title);
        this.analyticsService.pageView(title);
      } else {
        this.titleService.setTitle(defaultPageTitle);
        // TODO FIXME? Do we need to call `this.analyticsService.pageView(defaultPageTitle)`?
      }
    });

    // Set default datatable settings
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;

    this.resize$.subscribe(() => {
      // recalculate all columns on all tables
      ($.fn.dataTable.tables({ visible: false, api: true }) as DataTables.Api).columns.adjust();
    });

    $.extend(true, $.fn.dataTable.defaults, {
      dom: `<'row'<'col-sm-12'f>><'row'<'col-sm-12'tr>><'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>><'row'<'col-12'${
        isDemoSite ? '' : 'B'
      }>>`,
      deferRender: true,
      language: {
        search: '',
        searchPlaceholder: 'Search...',
      },
      buttons: [
        {
          extend: 'excelHtml5',
          text: 'EXCEL',
          className: 'btn btn-link p-1',
        },
        {
          extend: 'csvHtml5',
          text: 'CSV',
          className: 'btn btn-link p-1',
        },
      ],
      columnDefs: escapeTextByDefault,
      footerCallback() {
        const api = new $.fn.dataTable.Api(this);
        for (let i = 0; i < api.columns().count(); i++) {
          // Look for a footer with the attribute data-subtotal
          const footer = api.column(i).footer();
          if (!footer) {
            continue;
          }
          if (footer.hasAttribute('data-subtotal')) {
            try {
              const total = api
                .column(i, { search: 'applied' })
                .data()
                // We may have columns of strings with numbers, get these numbers.
                .map(a => (typeof a === 'number' ? a : numbersFromString(a)))
                .map(a => Number(a))
                .filter(a => isFinite(a))
                .reduce((a, b) => a + b, 0);
              {
                let totalToDisplay = `${total}`;

                if (footer.hasAttribute('data-number')) {
                  const precision = footer.getAttribute('data-number');
                  totalToDisplay = that.decimalPipe.transform(total, precision);
                } else if (footer.hasAttribute('data-currency')) {
                  // At this point, unable to get Assessment instance (to determine the specific currency for the assessment). Defaults to AUD
                  totalToDisplay = `${that.currencyConverterPipe.transform(total)}`;
                } else if (footer.hasAttribute('data-currency-symbol')) {
                  // At this point, unable to get Assessment instance (to determine the specific currency for the assessment). Defaults to AUD
                  totalToDisplay = `${that.currencyConverterPipe.transform(total, 'none')}`;
                } else if (footer.hasAttribute('data-percent')) {
                  const percentageTotal = total > 100 ? 100 : total;
                  totalToDisplay = `${that.precisionPipe.transform(percentageTotal, 0)}%`;
                } else if (footer.hasAttribute('data-precision')) {
                  totalToDisplay = that.precisionPipe.transform(total);
                }

                // Set the value to data attribute. For scss in component that use this for dynamic css classes,
                // eg. show different colour for different value.
                // TODO: Move logic for dynamic css classes to be contained within the angular component that needs this.
                // When we use ag-grid, this will be dropped since this is only for jQuery DataTables.
                $(api.column(i).footer()).text(totalToDisplay).attr('data-content', totalToDisplay);
              }
            } catch {
              console.warn(`Unable to total column ${i} in ${this[0].id}`);
            }
          }

          if (i === 0) {
            // Display a disclaimer on the first footer column that rounding may affect the totals
            const unsafeFooterText = $(api.column(i).footer()).text();
            $(api.column(i).footer()).html(staticTotalsTemplate).children().text(unsafeFooterText);
          }
        }
      },
    });
  }

  ngAfterViewChecked() {
    // Bootstrap tooltips are opt-in so they must be initialised first
    $('[data-toggle="tooltip"]').tooltip();
  }

  accept() {
    this.userService
      .updateTermsOfUseAgreement$()
      .pipe(
        first(),
        tap(() => {
          $('#termsOfUseModal').modal('hide');
          // Navigate to the original path requested prior to accepting the agreement
          this.router.navigateByUrl(sessionStorage.getItem('tou-redirect-path') || '/');
          // Clear the terms of use cookie
          sessionStorage.removeItem('tou-redirect-path');
        }),
      )
      .subscribe();
  }

  decline() {
    $('#termsOfUseModal').modal('hide');
    // Cannot proceed, log the user out.
    this.authService.logout$().subscribe();
  }
}

const staticTotalsTemplate =
  '<span role="button" data-toggle="tooltip" data-placement="top" title="Totals may be affected by the rounding of decimal places and should only be used as an approximation"></span>';

import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-server-error-modal',
  standalone: true,
  templateUrl: './server-error-modal.component.html',
  styleUrls: ['./server-error-modal.component.scss'],
  imports: [CommonModule],
})
export class ServerErrorModalComponent {
  constructor(
    public bsModalRef: BsModalRef,
    private router: Router,
  ) {}

  refresh() {
    this.bsModalRef.hide();
    this.router.navigateByUrl('/');
  }
}

<!--
 Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
 Unauthorized copying of this file, via any medium is strictly prohibited.
 Proprietary and confidential.
-->

<router-outlet (activate)="showFooter = true" (deactivate)="showFooter = false"></router-outlet>
<app-alert></app-alert>
<app-footer *ngIf="showFooter"></app-footer>

<!-- set style `display: none;` to hide this content from brief flash appearance on screen during first page load. Due to html content loaded first but the bootstrap styles haven't yet. We can't use `d-none` here as bootstrap styles not available until after child components are loaded. -->
<!-- set modal to be static; don't allow it to be closed via escape keypress or click in the background. -->
<div class="modal fade" style="display: none" id="termsOfUseModal" tabindex="-1" role="dialog" aria-labelledby="termsOfUseLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
    <div class="modal-content block">
      <h1 id="termsOfUseLabel">FairSupply Terms of Use</h1>
      <div class="mt-4">
        <ol class="text-standard">
          <li>
            <b>Terms of Use</b>
            <ol>
              <li>
                <div>By clicking <b>"I ACCEPT"</b>, you acknowledge and agree on your behalf and on behalf of any company you represent that:</div>
                <ol type="a">
                  <li>your use of the Website and access to the Website Content is subject to the Disclaimer (accessible at this link www.fairsupply.com.au) and the terms of any SaaS Agreement between you or the company you represent and Fair Supply;</li>
                  <li>you have read, understand and agree to these terms of use of the Website and access to the Website Content.</li>
                </ol>
              </li>
            </ol>
          </li>

          <li>
            <b>Methodology</b>
            <ol>
              <li>
                <div>By clicking <b>"I ACCEPT"</b>, you acknowledge and agree on your behalf and on behalf of any company you represent that:</div>
                <ol type="a">
                  <li>certain information or material on the Website and in the Website Content may contain material obtained or provided directly by third parties which Fair Supply assumes has been given in good faith and is accurate at its issue date (Third Party Material); and</li>
                  <li>while Third Party Material is published by Fair Supply with necessary permissions, Fair Supply nor its officers, employees, consultants or agents accepts responsibility for the accuracy or completeness of such Third Party Material and to the maximum extent permitted by law, Fair Supply gives notice to you that it excludes all liability in respect of the Third Party Material.</li>
                </ol>
              </li>

              <li>
                <div>By clicking <b>"I ACCEPT"</b>, you acknowledge and agree on your behalf and on behalf of any company you represent that:</div>
                <ol type="a">
                  <li>Fair Supply provides an indicative assessment and analysis of theoretical risk in respect of modern slavery matters based on defined methodology and data sets and information provided by you and/or the company you represent but does not provide confirmations on actual instances of modern slavery. Fair Supply does not represent or warrant the accuracy or completeness of any modern slavery analysis or the Website Content or that the Website is free from any errors, omissions or defects; and</li>
                  <li>
                    <span>FairSupply's risk mitigation scores are FairSupply's subjective opinion only and dependent on:</span>
                    <ol type="i">
                      <li>Information contained in the company's modern slavery statement under the Modern Slavery Act 2018 (Cth) publicly available on the Australian Government's modern slavery registry; and/or</li>
                      <li>FairSupply's subjective assessment of the company's response to a company self-assessment questionnaire and/or</li>
                      <li>FairSupply's proprietary scoring methodology.</li>
                    </ol>
                  </li>
                  <li>
                    <span>the accuracy of the greenhouse gas analysis <b>(GHG Analysis)</b> provided by Fair Supply is dependent on the:</span>
                    <ol type="i">
                      <li>methodology being used in respect of the GHG Analysis; and</li>
                      <li>whether another methodology is available that would provide more accurate results;</li>
                    </ol>
                    <span>and Fair Supply provides results in respect of the GHG Analysis that are accurate on the basis of the defined methodology and data sets.</span>
                  </li>
                </ol>
              </li>

              <li>A link to the methodology and data sets used by Fair Supply can be made available upon request to info&#64;fairsupply.com.au.</li>
            </ol>
          </li>

          <li>FairSupply is not liable for any indirect, incidental, special or consequential loss or damage, loss of profits or anticipated profits, economic loss, loss of business opportunity, loss of data, loss of reputation or loss of revenue (irrespective of whether the loss or damage is caused by or relates to breach of contract, tort (including negligence), statute or otherwise) arising out of or in connection with the Website, the Website Content or all links to or from the Website.</li>
        </ol>

        <div class="float-right">
          <button class="btn btn-primary mr-4" (click)="accept()">I ACCEPT</button>
          <button class="btn btn-outline" (click)="decline()">I DECLINE</button>
        </div>
      </div>
    </div>
  </div>
</div>

// @ts-strict-ignore
import { Injectable, Injector } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ViewOnDesktopModalComponent } from './ui/modal/view-desktop-modal/view-on-desktop-modal.component';

@Injectable({
  providedIn: 'root',
})
export class IsMobileService {
  private readonly breakpoint = 700;
  private readonly viewDesktopKey = 'seenViewDesktop';

  constructor(private injector: Injector) {}

  private get modalService(): BsModalService {
    return this.injector.get(BsModalService);
  }

  detectMobile() {
    const lastSeenDate = new Date(sessionStorage.getItem(this.viewDesktopKey));
    if (window.innerWidth <= this.breakpoint && lastSeenDate.getTime() !== this.getCurrentDate().getTime()) {
      this.modalService.show(ViewOnDesktopModalComponent, { backdrop: 'static' });
      sessionStorage.setItem(this.viewDesktopKey, this.getCurrentDate().toString());
    }
  }

  private getCurrentDate(): Date {
    return new Date(new Date().setHours(0, 0, 0, 0));
  }
}

import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-view-desktop',
  standalone: true,
  templateUrl: './view-on-desktop-modal.component.html',
  styleUrls: ['./view-on-desktop-modal.component.scss'],
  imports: [CommonModule],
})
export class ViewOnDesktopModalComponent {
  constructor(public bsModalRef: BsModalRef) {}
}

// Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.
import { CommonModule, CurrencyPipe, DecimalPipe } from '@angular/common';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ModalModule } from 'ngx-bootstrap/modal';

import { PlotlyModule } from 'angular-plotly.js';
import * as PlotlyJS from 'plotly.js-basic-dist-min';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { tokenInterceptorFn } from './core/auth/token.interceptor';
import { AbsoluteValuePipe } from './shared/pipes/absolute-value.pipe';
import { CurrencyConverterPipe } from './shared/pipes/currency-converter.pipe';
import { IntensityDisplayPipe } from './shared/pipes/intensity-display.pipe';
import { PrecisionPipe } from './shared/pipes/precision-pipe';
import { SharedModule } from './shared/shared.module';
import { UiModule } from './ui/ui.module';

PlotlyModule.plotlyjs = PlotlyJS;

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    UiModule,
    SharedModule,
    BrowserAnimationsModule,
    ModalModule.forRoot(),
    PlotlyModule,
  ],
  providers: [
    provideHttpClient(withInterceptors([tokenInterceptorFn])),
    PrecisionPipe,
    DecimalPipe,
    CurrencyPipe,
    CurrencyConverterPipe,
    AbsoluteValuePipe,
    IntensityDisplayPipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
